.title1{
font-family: Work Sans;
font-size: 55px;
font-style: normal;
font-weight: 300;
line-height: 66px;
color: white;
text-align: left;
}
.banner3{
    background-color: #4168AC;
}
.title-para{
font-family: Work Sans;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px;
letter-spacing: -1%;
text-align: left;
color: white;
}
.btn-txt{
color:white;
font-family: Work Sans;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 28.8px;
letter-spacing: 1%;
}
.btn{
    border: 2px solid white;
}