.superCon {
  background-color: #2D2D2D;
  max-width: 100%;
}
.rigt {
  width: 49px;
  color: black;
  background-color: #e6e6e6;
  border-radius: 40px;
}
.common_width {
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 99px;
  padding-right: 99px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.arrowryt {
  color: #000000;
  font-size: 1000px;
}
.headingTech {
  color: black;
  font-family: Work Sans;
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
  /* padding-left: 99px;
  padding-top: 100px; */
  margin-bottom: 0;
}
.fontColRec {
  color: #ffffff !important;
  padding-bottom: 60px;
}
.flexi {
  display: flex;
}
.gridSys {
  display: grid;
  grid-template-columns: 177px 177px 177px;
  grid-column-gap: 100px;
  grid-row-gap: 40px;
  padding-left: 100px;
  color: #000000;
  padding-top: 72px;
}
.uk-slider-items > * {
  max-width: max-content !important;
}
.sectionz {
  width: 302px;
  margin-top: 72px;
  color: #000000;
  font-family: Work Sans;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}
.boxTech {
  display: flex;
  height: 55px;
  width: max-content;
  margin-top: -12px;
  /* margin-left: 124px; */
  background: #ffffff;
  padding: 10px 50px 10px 10px;
  box-shadow: 0px 4px 14px rgba(172, 172, 172, 0.25);
  border-radius: 10px;
}
.boxTechTxt {
  font-family: Work Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin: 7px 0 0 15px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}
.sliderHead {
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}
.up {
  margin-top: 20px;
}
.slidertype {
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  opacity: 0.6;
  margin-bottom: 0;
  margin-top: 0;
}
.flexslid {
  display: flex;
  align-items: center;
}
.ig {
  height: 34px;
  width: 34px;
  border-radius: 0px;
}
.dcre {
  margin-top: 26px;
  margin-bottom: 20px;
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  overflow: hidden;
  width: 310px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.linkw {
  color: #238ef1 !important;
}
.sliderbox {
  background-color: white;
  padding: 10px 10px 0 10px;
  border-radius: 0px;
}
/* .headImg {
  width: 350px;
  height: 199px;
} */
.belowbx {
  background-color: white;
  padding: 0 20px 34px 20px;
}
.boxio {
  height: 461px;
  width: 350px;
  background-color: white;
}
.belowtxt {
  font-family: Work Sans;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  height: 96px;
  width: 743px;
  margin: 58px auto 0 auto;
}
@media only screen and (max-width: 450px) {
  .boxio {
    height: 429px;
    width: 259px;
    border-radius: 0px;
  }
  .headImg {
    /* height: 147px; */
    width: 259px;
    border-radius: 0px;
  }
  .belowtxt {
    height: 90px;
    width: 336px;
    font-family: Work Sans;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: nullpx;
  }
  .common_width {
    padding: 30px;
  }
  .headingTech {
    font-family: Work Sans;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 30px;
  }
  .dcre {
    height: 88px;
    width: 233px;
    border-radius: nullpx;
    margin-top: 22px;
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 0;
  }
  .belowbx {
    padding: 10px 10px 20px 10px;
  }
  .sliderHead {
    font-family: Work Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 22px;
  }
  .flexi {
    display: block;
  }
  .gridSys {
    display: grid;
    grid-template-columns: 167px 167px;
    grid-column-gap: 16px;
    grid-row-gap: 20px;
    padding-left: 0;
    color: #000000;
    padding-top: 20px;
  }
  .sectionz {
    width: 215px;
    margin-top: 30px !important;
    color: #000000;
    font-family: Work Sans;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 0;
  }
  .headingTech {
    padding-bottom: 40px;
  }

  .boxTech {
    min-width: 167px;
    padding: 10px;
  }
}
