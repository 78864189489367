.banner{
  background-color: black;
}
/* .srow{
  height: 88vh;
} */
.banner-img{
  position: absolute;
    width: 449.06px;
    height: 329px;
    top: 80px;
}
.banner-link{
  color: white;
  text-decoration: underline;
  text-decoration-thickness: 3px;
 text-decoration-color: #7B61FF;
}
banner-link:hover{
  color: white;
  text-decoration: none;
}
.rec-text-1{
    font-size: 55px;
    line-height: 66px;
    color: white;
    font-weight: 300;
    font-family: 'Work Sans', sans-serif;
}

@media only screen and (max-width: 600px) {
  .rec-text-1 {
    font-size: 36px;
    line-height: 46px;

  }
}

.secnd-para{
  color: #9F9F9F;
  font-size: 17px;
  line-height: 26.35px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  font-style: normal;
}

.rec-text2{
  font-family: 'Work Sans', sans-serif;
    color: black;
    font-size: 36px;
    line-height: 47px;
    font-weight: 700;
}
@media only screen and (max-width: 600px) {
  .rec-text2 {
    font-size: 25px;
    line-height: 37px;

  }
}
  .test{
    height: 94px;
width: 850px;
left: 215px;
top: 120px;


  }
  .test1{
    height: 156px;
width: 650px;
left: 315px;
top: 254px;
border-radius: nullpx;

  }
.rec-text3{
    color: black;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    
   
}
@media only screen and (max-width: 600px) {
  .rec-text3 {
    font-size: 15px;
    

  }
}

  
  .btn-txt1{
    color: white;
    font-family: cursive;
    font-style: italic;
  }
  /* .btn-txt1:hover{
    color: white;
    text-decoration: underline;
  text-decoration-thickness: 3px;
 text-decoration-color: #0073e6;
  } */
  .btn-txt2{
    color: #9F9F9F;
    font-family: cursive;
    font-style: italic;
  }
  /* .btn-txt2:hover{
    color: #9F9F9F;
    text-decoration: underline;
  text-decoration-thickness: 3px;
 text-decoration-color: #0073e6;
  } */
  .img-styl{
    width: 11rem;
  }
  /* .final{
    height: 199px;
    width: 322px;
    left: 471px;
    top: 492px;
    border-radius: 0px;

  }
  .lastpik{
    position: absolute;
left: 36.81%;
right: 37.99%;
top: 39.45%;
bottom: 44.59%;
background: #FFFFFF;
border: 1px solid #000000;
  } */