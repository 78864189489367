.banner{
    width: 100%;
    background-color: black;
    color: white;
    
}
.text1{
    
    left: 0px;
    top: 0px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 48px;
    line-height: 58px
}

.text2{
    position: static;
/* width: 451px; */
height: 66px;
left: 0px;
top: 212px;
}
.btn1{
    border: 3px solid white;
    background-color: black;
    color: white;
    font-weight: 700;
    font-size: 17px;
   

}
 @media only screen and (max-width: 600px) {
    .btn1 {
        
        font-size: 9px;
        
      
    }
  } 

.btn2{
    border: 3px solid white;
    background-color: white;
    color: black;
    font-weight: 700;
    font-size: 17px;
    
}
@media only screen and (max-width: 600px) {
    .btn2 {
      
        font-size: 10px;

    }
  }
  .round-button {
	box-sizing: border-box;
	display:block;
	width:80px;
	height:80px;
	padding-top: 14px;
	padding-left: 8px;
	line-height: 20px;
	border: 6px solid #fff;
	border-radius: 50%;
	color:#f5f5f5;
	text-align:center;
	text-decoration:none;
	background-color: rgba(0,0,0,0.5);
	font-size:20px;
	font-weight:bold;
	transition: all 0.3s ease;
}
.round-button:hover {
	background-color: rgba(0,0,0,0.8);
	box-shadow: 0px 0px 10px rgb(122, 122, 122);
	text-shadow: 0px 0px 10px rgb(172, 172, 172);
}



