.menu-icon{
color: #bfbfbf;
}
.icon-text{
    font-size: 40px;
    color: black;
    font-weight: 400;
}
.menu-text{
    font-size: 20px;
}