.provider {
  font-family: Work Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28.8px;
  letter-spacing: 1%;
  text-align: left;
  color: black;
}
.ross {
  display: flex;
  align-items: center;
}
.atag {
  color: black;
  text-decoration: none;
  font-family: Work Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -1%;
  text-align: left;
}
