h3{
    color: white;
}
p{
    color: white;
}
.footer-set{
    
   
    background:  black;
}
.footer-title{
    color: white;
    text-decoration: underline;
    
}
.footer-txt{
    color: white;
    
}
.footer-icon-2{
    padding: 1em;
    
}
/* .icon-foot{
    padding-right: 1em;
}
.footer-phone{
    margin-left: -41px;

}
.footer-mail{
    margin-left: 25px;
} */
.icons{
    color: white;
}