.icon-text2{
    font-size: 50px;
    font-weight: 400;
    
}
.menter-text{
    font-size: 22px;
    color: black;
    font-weight: 500;
  
}