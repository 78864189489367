.process-text-1{
    font-size: 41px;
    font-weight: 700;
    
}
.head{
    font-weight: 700;
    color: black;
}
.procard-text{
    font-weight: 700;
    font-size: 27px;
    color: black;

}
.pro-num{
     font-size: 50px;
    color: black;
    font-weight: 400;
}