.numb{
font-family: Work Sans;
font-size: 96px;
font-style: normal;
font-weight: 300;
line-height: 115px;
letter-spacing: -1%;

color: black;
}
.numb-data{
color: black;
font-family: Work Sans;
font-size: 16px;
font-style: normal;
font-weight: 300;
line-height: 19px;
letter-spacing: -0.01em;
}
.numb-data2{
font-family: Work Sans;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 58px;
letter-spacing: 1%;
text-align: left;
color: black;
}
.numb-para{
color: black;
font-family: Work Sans;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: 30px;
letter-spacing: -1%;


}