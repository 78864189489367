.title{
font-family: Work Sans;
font-size: 48px;
font-style: normal;
font-weight: 700;
line-height: 58px;
letter-spacing: 1%;
text-align: left;
color: black;
}
.list{
margin-top: 10px;
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -1%;
    text-align: left;
    color: black;
    
}
.uk-list-square>::before {
    list-style-type: square;
    font-size: 2em;

}