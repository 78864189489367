.artistimg {
  height: 424px;
  width: 424px;
  border-radius: 2px;
  object-fit: cover;
}
.textreletive {
  margin-left: 60px;
  position: relative;
}
.name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 41px;
  color: #000000;
  margin-bottom: 0;
}
.description {
  width: 655px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 0;
}
.buttuns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-inline: 12px 18px;
  gap: 10px;
margin-top: 75px;
  /* position: absolute; */
  width: 163.32px;
  height: 43px;
 /* left: 584px; */
  /* top: 70px; */

  background: #000000;
  border-radius: 100px;
}
.buttontxt {
  width: 127px;
  height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.9);
}
