.home-text1{
    font-size: 40px;
    color: black;
}
.home-text2{
    color: black;
    font-size: 28px;
    font-weight: 750;
}
.home-text-data{
    font-size: 17px;
    color: black;
    font-weight: 400;
}
.btn3{
    border: 3px solid black;
    background-color: white;
    color: black;
    font-weight: 700;
    font-size: 17px;
    height: 70px;
}
@media only screen and (max-width: 600px) {
    .btn3 {
        font-size: 13px;
        height: 50px;
    }
  }
.btn4{
   
    border: 3px solid black;
    background-color: black;
    color: white;
    font-weight: 700;
    font-size: 17px;
    height: 70px;

}
@media only screen and (max-width: 600px) {
    .btn4 {
        font-size: 13px;
        height: 50px;
    }
  }
  @media only screen and (max-width: 600px) {
    .btn1 {
    
        font-size: 13px;
        height: 50px;
    }
  }