.headTesti{
/* position: absolute; */

/* height: 112px; */
/* left: 90px; */
/* top: 119px; */
font-family: Work Sans;
font-style: normal;
font-weight: bold;
font-size: 40px;
line-height: 37px;
color: black;
}
.headTesti1{
    /* position: absolute; */
    /* width: 335px; */
    /* height: 96px; */
    /* left: 90px; */
    /* top: 257px; */
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    line-height: 24px;
    color: #000000;
}
.card-title{
    color: black;
    font-size: 20px;
    /* width: 287px; */
    font-weight: 500;
}
.card-name{
    color: black;
    font-weight: 700;
    font-size: 20px;
}
.card-post{
    color: black;
    font-size: 14px;
}
.nxt-btn{
    color: #cccccc;
    background-color: white;
    font-size: 50px;
}
.nxt-btn:hover{
    color: black;
    background-color: white;
    font-size: 50px;
}