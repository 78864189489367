.navbars{
    
    background-color: black;
    /* position: fixed; */
    z-index: 1;
 }
.nav-img{
    
    width: 75px;
    top: 12px;
}
a{
    text-decoration: none;
}