.cardos {
  /* height: 132px; */
  padding: 16px;
}
@media screen and (max-width: 1100px) {
    .cardos {
        
        padding: 16px;
      }
}
.desig{
  margin-top: 5px;
  font-size: 18px;
  margin-bottom: 0;
}
.flexo {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  padding-left: 0;
  color: #000000;
  padding-top: 20px
px
;
}
@media screen and (max-width: 600px) {
  .flexo {
    grid-template-columns: 1fr 1fr ;
  }
}
.imgTeam {
  object-fit: cover;
  width: 200px;
  height: 200px;
  margin-top: 20px;
}
