.navbars {
  background-color: white;
  box-shadow: 0px 4px 30px rgb(172 172 172 / 50%);
  /* position: fixed; */
  z-index: 1;
  height: 80px;
}
.nav-img {
  margin-left: 30px;
  width: 200px;
  top: 12px;
}
@media only screen and (max-width: 600px) {
  .nav-img {
    margin-left: 15px;
      width: 127px;
      
  }
}
a {
  text-decoration: none;
}
.nav-text {
  color: black !important;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: right;
  text-decoration: none;
}
.nav-textss {
  color: black !important;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: right;
  text-decoration: none;
}
.nav-text:hover {
  color: #9f9f9f;
  text-decoration: none;
}
