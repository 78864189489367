.first {
  /* font-family: 'Sohne Test'; */
  font-size: 17px;
  font-style: normal;
  /* font-weight: 400; */
  line-height: 22px;
  color: black;
}

.imgwid {
  height: 64px;
  width: 64px;
  border-radius: 0px;
  margin-right: 15px;
  margin-bottom: 0px;
  border-radius: 40px;
}
.flei {
  display: flex;
}
.sidebar-item-active{
 background-color: #b0d1f0;
 border-radius: 10px;
 padding-left: 10px;
}

.panalis {
  display: flex;
  width: 320px;
  padding-bottom: 19px;
  border-top: 1px solid #d4d2cf;
  padding-top: 22px;
}

.marginal {
  margin-top: 80px;
}
.second {
  font-family: "Sohne Test";
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.second:active, .second:focus, .second::selection{
  color: red;
}
.third {
  /* font-family: 'Sohne Test'; */
  font-size: 13px;
  font-style: normal;
  /* font-weight: 400; */
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #888888;
}
.vl {
  border-left: 1px solid #d4d2cf;
  /* height: 500px; */
}
@media only screen and (max-width: 780px) {
  .disspl {
    display: none !important;
  }
  .panalis {
    margin-bottom: 19px;
  }
  .marginal {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 781px) {
  .disspl {
    display: block !important;
  }
}
