.t-one{
color: white;
    font-family: Work Sans;
    font-size: 55px;
    font-style: normal;
    font-weight: 300;
    line-height: 66px;
    letter-spacing: 0em;
  
    
}