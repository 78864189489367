h3 {
  color: white;
}
p {
  color: black;
}
.footer-set {
  width: 100%!important;
  padding: 0 50px;
  border-top: 1px solid #d4d2cf;
  /* opacity: 0.2; */
  background: white;
  /* position: absolute;
    bottom: 0; */

}
.footer-title {
  color: white;
  text-decoration: underline;
}
.footer-txt {
  color: white;
}
.footer-icon-2 {
  padding: 1em;
}
/* .icon-foot{
    padding-right: 1em;
}
.footer-phone{
    margin-left: -41px;

}
.footer-mail{
    margin-left: 25px;
} */
.icons {
  color: white;
}
.foot-text {
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  color: white;
}
@media only screen and (max-width: 600px) {
  .foot-text {
    font-size: 15px;
  }
  .footer-set {
    margin-top: 0px !important ;
  }
}
.vec {
  left: 8.33%;
  right: 8.33%;
  top: 14.09%;
  bottom: 14.09%;
}
.card-title {
  font-family: "Work Sans", sans-serif;
  font-size: 31px;
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0em;
  color: white;
}
@media only screen and (max-width: 600px) {
  .card-title {
    font-size: 25px;
  }
}
