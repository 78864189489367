.navbars {
  background-color: black;
  /* position: fixed; */
  z-index: 1;
}
a:focus {
  /* background-color: #468cea; */
  /* width:max-content;
  height: 54px;
  border-radius: 10px; */
}
.nav-img {
  width: 91px;
  top: 12px;
}
.navBar {
  position: fixed;
  top: 0;
  width: 100%;
}
a {
  text-decoration: none;
}
.nav-text {
  color: white;
  font-family: "Work Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
}
.nav-text2,
.nav-text:hover {
  text-decoration: none;
}
.nav-text2,
.nav-text:focus {
  text-decoration: none;
}
.nav-text2 {
  color: white;
  font-family: "Work Sans", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  text-decoration: none;
}
.nav-text2,
.nav-text:hover {
  color: #9f9f9f;
  text-decoration: none !important;
}
.emp {
  width: 183px;
  height: 40px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  /* display: flex;
  align-items: center;
  text-align: center; */

  left: 55%;
  background: #468cea;
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
}
.emp > span > p {
  color: #ffffff !important;
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .nav-text2,
  .nav-text {
    color: black;
    text-decoration: none !important;
  }
  .fleix {
    box-shadow: 0px 4px 30px rgb(172 172 172 / 50%);
    display: flex;
    align-items: center;
    position: fixed;
    height: 53px;
    width: 100%;
    background-color: white;
    z-index: 6;
  }
  .menubox {
    position: absolute;
    right: 0px;
    top: 9px;
    background-color: transparent;
  }
  .emp {
    width: max-content;
    height: 33px;
    font-size: 12px;
    left: 48%;
    background: #468cea;
    border-radius: 6px;
    position: absolute;
    top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .bg {
    background-color: white;
  }
  .bgli {
    color: black;
  }
  .nav-text2 {
    color: black;
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-decoration: none;
  }
  .uk-list > * > :last-child {
    font-family: Work Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    margin: 7px 0 0 15px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
    /* border-bottom: solid 1px black; */
  }
  .homees{
    width: 35px;
  }
  .cl{
    background: transparent;
    color: black;
    width: 40px;
    position: absolute;
    top: 8px;
}
  .emp > span > p {
    color: #ffffff !important;
    margin-bottom: 0px;
  }
  /* .nav-text2,
  .nav-text:hover {
    color: #9f9f9f;
  } */
}
