.block-title{
 font-family: Work Sans;
font-style: normal;
font-weight: bold;
font-size: 48px;
line-height: 120%;
letter-spacing: 0.01em;
color: #FFFFFF;
}
.box1{
    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 30px;

/* position: absolute; */
width: 360px;
height: 120px;
left: 90px;
top: 216px;

background: #262626;
}
.box-text{
    font-family: Work Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.01em;
    text-align: left;
    color: black;
    }
    .box-text3{
        font-family: Work Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: -0.01em;
        text-align: left;
        color:white;
        }
    .cardbg{
        background-color:white;
        border: 2px solid #4168AC
    }