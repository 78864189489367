.company {
  /* font-family: 'Sohne Test'; */
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}
p {
  color: black;
}

.position {
  font-family: Test Signifier;
  font-size: 41px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px;
  text-align: left;
  color: black;
}
.loc {
  /* font-family: Sohne Test; */
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #888888;
}
.details {
  padding-right: 63px;
  /* font-family: Sohne Test; */
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: black;
}
.btn_one {
  color: white;
  height: 54px;
  width: 164px;
  border-radius: 8px;
  padding: 16px, 40px, 16px, 40px;
  background-color: #468cea;
}
.btn_two {
  height: 54px;
  width: 151px;
  border-radius: 8px;
  padding: 16px, 40px, 16px, 40px;
}
.margos {
  margin-top: 50px;
}
.co {
  /* font-family: Sohne Test; */
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #888888;
}
.linkid {
  /* font-family: Sohne Test; */
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-decoration: underline solid 2px;
  color: #0066ff;
}
.ico {
  font-size: 14px;
  color: #0066ff;
}
.oi {
  /* font-family: Test Signifier; */
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.hed {
  /* font-family: Sohne Test; */
  font-size: 21px;
  line-height: 33px;
}
.listin {
  /* font-family: Sohne Test; */
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.borders {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  border-right: 5px solid black;
}
.backto{
  display: none;
}
@media only screen and (max-width: 780px) {
  .company {
    margin-top: 45px;
  }
  .details {
    padding-right: 20px;
  }
  .ppding {
    margin-left: 0px !important;
    padding: 30px;
    margin-top: 0px !important;
  }
  .backto{
    display: block;
    width: 40px;
    box-shadow: 1px 68px 53px rgb(172 172 172 / 50%);
    background: #f2f2f2;
    height: 40px;
    padding: 7px;
    border-radius: 24px;
  }
}
